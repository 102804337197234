<template>
  <div class="row">
    <div class="col-2"/>

    <div class="col-6">

      <draggable :list="list">
        <div
          v-for="element in list"
          :key="element.ten"
          style="width: 140px; height: 100px; background: tomato; padding: 40px; margin: 20px"
        >
          {{ element.ten }}
        </div>
      </draggable>
    </div>

  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      list: [
        { ten: "Le" },
        { ten: "Kha" },
        { ten: "Hai" },
      ]
    };
  },
 
  
};
</script>
